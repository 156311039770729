<div class="container">
    <div class="row align-items-center">
        <div class="col-lg-6 col-md-12">
            <div class="feedback-image">
                <img src="assets/img/feedback-img1.jpg" alt="image">
                <img src="assets/img/feedback-img2.jpg" alt="image">
                <img src="assets/img/feedback-img3.jpg" alt="image">
                <img src="assets/img/feedback-img4.jpg" alt="image">
            </div>
        </div>

        <div class="col-lg-6 col-md-12">
            <div class="feedback-content">
                <span class="sub-title"></span>
                <h2>Ready to find out more?</h2>

                <div class="feedback-slides owl-carousel owl-theme">
                    <div class="single-feedback-item">
                        <p>
                            We provide Application Development and Web Development Services. To asses your current systems and get quotes on development services to upgrade your existing systems or enhance your current software applications, you can submit a query through contact form.
                        </p>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>