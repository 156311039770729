// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false,
/*  firebaseConfig: {
    apiKey: "AIzaSyB3mZO7Q3cgV6PCWyXNNbI4aHJBn6QDyME",
    authDomain: "tekspikes-d0538.firebaseapp.com",
    databaseURL: "https://tekspikes-d0538.firebaseio.com",
    projectId: "tekspikes-d0538",
    storageBucket: "tekspikes-d0538.appspot.com",
    messagingSenderId: "880454705828",
    appId: "1:880454705828:web:032eea5084ed7f37cd7c31"
  }*/

   firebaseConfig : {
    apiKey: "AIzaSyBTNSvaxIM5jwzaK358sVsFRZf_riCPgL8",
    authDomain: "tekspikeswebsite-46b57.firebaseapp.com",
    databaseURL: "https://tekspikeswebsite-46b57.firebaseio.com",
    projectId: "tekspikeswebsite-46b57",
    storageBucket: "tekspikeswebsite-46b57.appspot.com",
    messagingSenderId: "170702287895",
    appId: "1:170702287895:web:c3de559f0ade1ee7e9d852",
  }

};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
