<div class="container">
    <div class="row">
        <div class="col-lg-6 col-md-6 col-sm-6">
            <div class="single-boxes-box">
                <div class="icon">
                    <i class="flaticon-research"></i>
                    <div class="circles-box">
                        <div class="circle-one"></div>
                    </div>
                </div>
                <h3><a routerLink="/javaandui">Java/UI Full Stack Development Services</a></h3>
                <p>Tek Spikes provides development services on Java and Java Script technologies, with a pool of resources that can do both backend and front end development.</p>
                <a routerLink="/javaandui" class="learn-more-btn"><i class="left-icon flaticon-next-button"></i> Learn More <i class="right-icon flaticon-next-button"></i></a>
            </div>
        </div>

        <div class="col-lg-6 col-md-6 col-sm-6">
            <div class="single-boxes-box">
                <div class="icon">
                    <i class="flaticon-speed"></i>
                    <div class="circles-box">
                        <div class="circle-one"></div>
                    </div>
                </div>
                <h3><a routerLink="/devopsandcloud">DevOps & Cloud Consulting Services</a></h3>
                <p>Our employees can implement DevOps process from scratch and help your firm transition to cloud based DevOps processes for faster and efficient development iterations..</p>
                <a routerLink="/devopsandcloud" class="learn-more-btn"><i class="left-icon flaticon-next-button"></i> Learn More <i class="right-icon flaticon-next-button"></i></a>
            </div>
        </div>
    </div>
</div>