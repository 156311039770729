<section class="services-area bg-f9f9f9 pt-100 pb-70">
    <div class="container">
        <!-- <div class="section-title">
            <span class="sub-title">Services</span>
            <h2>Let’s Check Our Services</h2>
        </div> -->

        <div class="row">
            <div class="col-lg-4 col-md-6 col-sm-6">
                <div class="single-services-box">
                    <div class="icon">
                        <i class="flaticon-megaphone"></i>
                        <div class="circles-box">
                            <div class="circle-one"></div>
                            <div class="circle-two"></div>
                        </div>
                    </div>
                    <h3><a routerLink="devopsandcloud">Services</a></h3>
                    <p>Tek Spikes provides Application Development and Maintenance Outsourcing services that lead to business process improvement.</p>
                    <a routerLink="devopsandcloud" class="learn-more-btn"><i class="left-icon flaticon-next-button"></i> Learn More <i class="right-icon flaticon-next-button"></i></a>
                </div>
            </div>

            <div class="col-lg-4 col-md-6 col-sm-6">
                <div class="single-services-box">
                    <div class="icon">
                        <i class="flaticon-keywords"></i>
                        <div class="circles-box">
                            <div class="circle-one"></div>
                            <div class="circle-two"></div>
                        </div>
                    </div>
                    <h3><a routerLink="devopsandcloud">Industries</a></h3>
                    <p>Tek Spikes helps clients to be competitive and flexible through partnership with industry leaders. Tek Spikes's strategic business partnerships with.</p>
                    <a routerLink="devopsandcloud" class="learn-more-btn"><i class="left-icon flaticon-next-button"></i> Learn More <i class="right-icon flaticon-next-button"></i></a>
                </div>
            </div>

            <div class="col-lg-4 col-md-6 col-sm-6">
                <div class="single-services-box">
                    <div class="icon">
                        <i class="flaticon-content-management"></i>
                        <div class="circles-box">
                            <div class="circle-one"></div>
                            <div class="circle-two"></div>
                        </div>
                    </div>
                    <h3><a routerLink="devopsandcloud">Consulting</a></h3>
                    <p>By uncovering insights that create new futures. And, by doing the hard work to improve performance - from the back office to the corner office and everywhere.</p>
                    <a routerLink="devopsandcloud" class="learn-more-btn"><i class="left-icon flaticon-next-button"></i> Learn More <i class="right-icon flaticon-next-button"></i></a>
                </div>
            </div>
        </div>
    </div>
</section>