<div class="m-50-auto col-lg-6 col-md-12">
  <div class="login-form">
    <h2 class="text">Login</h2>

    <form #loginForm="ngForm" (ngSubmit)="submitLogin(loginForm)">
      <div>
        <div *ngIf="networkError" class="error">Network error occurred. Please try again.</div>
        <div *ngIf="loginError" class="error">Incorrect Email or password.</div>
        <div *ngIf="tooManyRequestsError" class="error">Too many requests. Please try again later.</div>
        <div *ngIf="loginForm.invalid" class="error">All fields are mandatory</div>
      </div>
      <div class="form-group">
        <label>Email</label>
        <input type="email" class="form-control" placeholder="Your Email" name="email" [(ngModel)]="email" required>
      </div>

      <div class="form-group">
        <label>Password</label>
        <input type="password" class="form-control" placeholder="Your Password" name="password" [(ngModel)]="password"
          required>
      </div>

      <!-- <button type="submit" [disabled]="loginForm.invalid">Log In</button> -->

      <div class="cart-buttons">
        <div class="row align-items-center">
            <div class="col-lg-7 col-sm-7 col-md-7">
                
            </div>

            <div class="col-lg-5 col-sm-5 col-md-5 text-right">
              <button type="submit" [disabled]="loginForm.invalid">Log In</button>
            </div>
        </div>
    </div>
    </form>
  </div>
</div>