<section class="page-title-area">
    <div class="container">
        <div class="page-title-content">
            <h1>Blog</h1>
        </div>
    </div>

    <div class="shape2"><img src="assets/img/shape/shape2.png" alt="image"></div>
    <div class="shape3"><img src="assets/img/shape/shape3.png" alt="image"></div>
    <div class="shape5"><img src="assets/img/shape/shape5.png" alt="image"></div>
    <div class="shape6"><img src="assets/img/shape/shape6.png" alt="image"></div>
    <div class="shape7"><img src="assets/img/shape/shape7.png" alt="image"></div>
    <div class="shape8"><img src="assets/img/shape/shape8.png" alt="image"></div>
    <div class="lines">
        <div class="line"></div>
        <div class="line"></div>
        <div class="line"></div>
    </div>
</section>

<!-- Before login template -->
<div *ngIf="loggedInUser === ''">
    <section class="blog-area bg-f9f9f9 ptb-100">
        <div class="container-fluid">
            <div class="row">
                <div class="col-lg-3 col-md-6" *ngFor="let blogPost of blogPosts">
                    <div class="single-blog-post">
                        <div class="image">
                            <a routerLink="" class="d-block">
                                <img onError="this.src='assets/img/blog/blog-img1.jpg'" [src]="blogPost.image" />
                            </a>
                        </div>

                        <div class="content">
                            <h3><a routerLink="/single-blog">{{blogPost.title}}</a></h3>
                            <div class="d-flex align-items-center">
                                <img onError="this.src='https://via.placeholder.com/100'" [src]="blogPost.image" />
                                <div class="info">
                                    <h5>David Smith</h5>
                                    <span>Jun 21, 2020</span>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </section>
</div>


<!-- After login-->

<div *ngIf="loggedInUser && loggedInUser !== ''">
    <section class="blog-area ptb-100" *ngIf="!showBlogDetails">
        <div class="container">
            <div class="blog-buttons">
                <div class="row align-items-center">
                    <div class="col-lg-7 col-sm-7 col-md-7"></div>
                    <div class="col-lg-5 col-sm-5 col-md-5 text-right">
                        <button type="submit" (click)="addDetails()">Add Blog</button>
                    </div>
                </div>
            </div>
            <form>
                <div class="blog-table table-responsive">
                    <table class="table table-bordered">
                        <thead>
                            <tr>
                                <th scope="col">Image</th>
                                <th scope="col">Title</th>
                                <th scope="col">Actions</th>
                            </tr>
                        </thead>

                        <tbody>
                            <tr *ngFor="let blogPost of blogPosts" (click)="showDetails(blogPost)">
                                <td class="product-thumbnail">
                                    <a routerLink="/single-products">
                                        <!-- <img src="assets/img/products/products-img1.jpg" alt="item"> -->
                                        <img onError="this.src='https://via.placeholder.com/100'"
                                            [src]="blogPost.image" />
                                    </a>
                                </td>

                                <td class="product-name">
                                    <a>{{blogPost.title}}</a>
                                </td>

                                <td class="product-subtotal">
                                    <a class="remove pr-10" (click)="editDetails(blogPost)"><i
                                            class='bx bx-edit'  style="cursor: pointer;"></i></a>
                                    <a class="remove" (click)="deleteDetails($event, blogPost)"><i
                                            class='bx bx-trash' style="cursor: pointer;"></i></a>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </form>
        </div>
    </section>


    <!-- More details -->
    <section class="products-details-area ptb-100" *ngIf="showBlogDetails && !editMode">
        <div class="container">
            <div class="blog-buttons">
                <div class="row align-items-center">
                    <div class="col-lg-7 col-sm-7 col-md-7"></div>
                    <div class="col-lg-5 col-sm-5 col-md-5 text-right">
                        <button type="submit" (click)="closeDetails()">Back</button>
                    </div>
                </div>
            </div>
            <div class="row align-items-center">
                <div class="col-lg-5 col-md-12">
                    <div class="products-details-image">
                        <a data-fancybox="gallery">
                            <img onError="this.src='assets/img/products/products-img1.jpg'"
                                [src]="selectedBlog.image" />
                        </a>
                    </div>
                </div>

                <div class="col-lg-7 col-md-12">
                    <div class="products-details-desc">
                        <h3>{{selectedBlog.title}}</h3>

                        <div class="price">
                            <span class="old-price">{{selectedBlog.dateStr}}</span>
                        </div>

                        <p [innerHTML]="selectedBlog.description"></p>
                    </div>
                </div>
            </div>
        </div>
    </section>

    <!-- Add or Edit-->
    <section class="contact-area ptb-100" *ngIf="showBlogDetails && editMode">
        <div class="container">
            <form #blogForm="ngForm" (ngSubmit)="saveDetails(blogForm, selectedBlog)">
                <div class="row">
                    <div class="col-lg-6 col-md-12">
                        <div class="contact-image">
                            <input type="file" #fileUploadEl class="upload-image-hidden" (change)="getFile($event, selectedBlog)" accept=".jpg,.jpeg,.png" />
                            <img onError="this.src='assets/img/contact.png'" [src]="selectedBlog.image" name="image" (click)="fileUploadEl.click()" />
                        </div>
                    </div>

                    <div class="col-lg-6 col-md-12">
                        <div class="contact-form">
                            <div class="row">
                                <div class="col-lg-12 col-md-6">
                                    <div class="form-group">
                                        <label>Title: </label>
                                        <input type="text" name="title" class="form-control" id="title" required
                                        [ngModelOptions]="{standalone: true}" [(ngModel)]="selectedBlog.title" data-error="Please enter title"
                                            placeholder="Your Title">
                                    </div>
                                </div>

                                <div class="col-lg-12 col-md-6">
                                    <div class="form-group">
                                        <label>Date: </label>
                                        <input type="date" name="publishedDate" class="form-control" id="publishedDate"
                                            required data-error="Please enter Date"
                                            [ngModelOptions]="{standalone: true}" [(ngModel)]="selectedBlog.dateCtrlStr" placeholder="Select Date">
                                    </div>
                                </div>

                                <!-- <div class="col-lg-12 col-md-12">
                                    <div class="form-group">
                                        <label>Description: </label>
                                        <input type="text" name="description" class="form-control" id="description"
                                            required data-error="Please enter description"
                                            [(ngModel)]="selectedBlog.description" placeholder="Description">
                                    </div>
                                </div> -->

                                <div class="col-lg-12 col-md-12" style="height:400px;">
                                    <div class="form-group">
                                        <label>Description: </label>
                                        <quill-editor [ngModelOptions]="{standalone: true}" [(ngModel)]="selectedBlog.description" #modelRef="ngModel"></quill-editor>
                                    </div>
                                </div>

                                <div class="col-lg-12 col-md-12" style="margin-top:150px;">
                                    <button type="button" (click)="closeDetails()"
                                        class="cancel-btn mr-10">Cancel</button>
                                    <button type="submit" [disabled]="blogForm.invalid"
                                        class="default-btn">Save</button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </form>
        </div>
    </section>
</div>

<!-- 
<section class="blog-area bg-f9f9f9 ptb-100">
    <div class="container-fluid">
        <div class="row">
            <div class="col-lg-12 col-md-12" *ngIf="!showBlogDetails">
                <button type="button" (click)="addDetails()">Add Blog</button>
                <table>
                    <tr>
                        <th>Image</th>
                        <th>Title</th>
                        <th>Actions</th>
                    </tr>
                    <tr *ngFor="let blogPost of blogPosts" (click)="showDetails(blogPost)">
                        <td>
                            <img onError="this.src='https://via.placeholder.com/100'" [src]="blogPost.image"
                                style="width:100px;height:100px;" />
                        </td>
                        <td>
                            {{blogPost.title}}
                        </td>
                        <td>
                            <span (click)="editDetails(blogPost)">Edit</span>
                            |
                            <span (click)="deleteDetails($event, blogPost)">Delete</span>
                        </td>
                    </tr>
                </table>
            </div>

            <div *ngIf="showBlogDetails && !editMode">
                <div>{{selectedBlog.title}}</div>
                <div>{{selectedBlog.dateStr}}</div>
                <div>{{selectedBlog.description}}</div>
                <img onError="this.src='https://via.placeholder.com/100'" [src]="selectedBlog.image"/>
                <div>
                    <button type="button" (click)="closeDetails()">Close</button>
                </div>
            </div>

            <div *ngIf="showBlogDetails && editMode">
                <form #blogForm="ngForm" (ngSubmit)="saveDetails(blogForm, selectedBlog)">
                    <div *ngIf="blogForm.invalid">All fields are required!!!</div>
                    <div><label>Title: </label> <input type="text" name="title" [(ngModel)]="selectedBlog.title"
                            required /></div>
                    <div><label>Date: </label> <input type="date" name="publishedDate"
                            [(ngModel)]="selectedBlog.dateCtrlStr" required /></div>
                    <div><label>Description: </label> <input type="text" name="description"
                            [(ngModel)]="selectedBlog.description" required /></div>
                    <img onError="this.src='https://via.placeholder.com/100'" [src]="selectedBlog.image" />
                    <div>
                        <button type="button" (click)="closeDetails()">Cancel</button>
                        <button type="submit" [disabled]="blogForm.invalid">Save</button>
                    </div>
                </form>
            </div>
        </div>
    </div>
</section> -->