import { Component, OnInit } from '@angular/core';
import * as firebase from 'firebase/app';
import { AuthService } from '../../../services/auth.service';

@Component({
  selector: 'app-careers',
  templateUrl: './careers.component.html',
  styleUrls: ['./careers.component.scss']
})
export class CareersComponent implements OnInit {
  db: firebase.firestore.Firestore;
  jobs: any[] = [];
  showJobDetails = false;
  editMode = false;
  selectedJob = null;
  loggedInUser = '';

  constructor( private authService: AuthService) {
    this.db = firebase.firestore();
  }

  ngOnInit(): void {
    this.loggedInUser = this.authService.userEmail;
    this.getJobs();
  }

  getJobs() {
    this.jobs = [];
    this.db.collection('jobs').get().then(snapshot => {
      // console.log(snapshot.docs);
      snapshot.docs.forEach(doc => {
        // console.log(doc.data());
        const data = doc.data();

        if (data.status === 'active') {
          data.id = doc.id;
          this.jobs.push(data);
        }
      });
    });
  }

  showDetails(data) {
    this.showJobDetails = true;
    this.selectedJob = data;
  }

  closeDetails() {
    this.showJobDetails = false;
    this.editMode = false;
    this.selectedJob = null;
  }

  editDetails(data) {
    this.showJobDetails = true;
    this.editMode = true;
    this.selectedJob = { ...data };
  }

  addDetails() {
    this.showJobDetails = true;
    this.editMode = true;
    this.selectedJob = {
      title: '',
      description: '',
      location: '',
      status: 'active'
    };
  }

  saveDetails(form, data) {
    if (form.valid) {
      const jobObj = {
        title: data.title,
        description: data.description,
        location: data.location,
        status: data.status
      };

      if (data.id) {
        this.db.collection('jobs').doc(data.id).set(jobObj, { merge: true }).then(() => {
          this.closeDetails();
          this.getJobs();
        });
      }
      else {
        this.db.collection('jobs').add(jobObj).then(() => {
          this.closeDetails();
          this.getJobs();
        });
      }
    }
  }

  deleteDetails(event, data) {
    event.stopPropagation();
    this.db.collection('jobs').doc(data.id).delete().then(() => {
      this.getJobs();
    });
  }

}
