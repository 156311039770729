import { Component, OnInit } from '@angular/core';
import * as firebase from 'firebase/app';
import * as moment from 'moment';
import { AuthService } from '../../../services/auth.service';

@Component({
  selector: 'app-blog-page',
  templateUrl: './blog-page.component.html',
  styleUrls: ['./blog-page.component.scss']
})
export class BlogPageComponent implements OnInit {
  db: firebase.firestore.Firestore;
  storageRef: firebase.storage.Reference;
  blogPosts: any[] = [];
  showBlogDetails = false;
  editMode = false;
  selectedBlog = null;
  loggedInUser = '';
  blogImage: File = null;

  constructor(private authService: AuthService) {
    this.db = firebase.firestore();
    this.storageRef = firebase.storage().ref();
  }

  ngOnInit(): void {
    this.loggedInUser = this.authService.userEmail;
    this.getBlogPosts();
    console.log(this.loggedInUser)
  }

  getBlogPosts() {
    this.blogPosts = [];
    this.db.collection('blog').get().then(snapshot => {
      // console.log(snapshot.docs);
      snapshot.docs.forEach(doc => {
        // console.log(doc.data());
        const data = doc.data();
        data.id = doc.id;
        const publishDate = moment(data.publishedDate.seconds * 1000);
        data.dateStr = publishDate.toDate().toDateString();
        data.dateCtrlStr = publishDate.format('YYYY-MM-DD');
        // console.log(data)
        this.blogPosts.push(data);
      });
    });
  }

  showDetails(data) {
    this.showBlogDetails = true;
    this.selectedBlog = data;
  }

  closeDetails() {
    this.showBlogDetails = false;
    this.editMode = false;
    this.selectedBlog = null;
    this.blogImage = null;
  }

  editDetails(data) {
    this.showBlogDetails = true;
    this.editMode = true;
    this.selectedBlog = { ...data };
  }

  addDetails() {
    this.showBlogDetails = true;
    this.editMode = true;
    this.selectedBlog = {
      title: '',
      description: '',
      author: 'John',
      dateCtrlStr: '',
      image: ''
    };
  }

  getFile(event, data) {
    console.log(event.target.files);
    this.blogImage = event.target.files.length ? event.target.files[0] : null;

    // Preview image using base64 data url
    if (this.blogImage) {
      const reader = new FileReader();
  
      reader.addEventListener("load", () => {
        data.image = reader.result;
      }, false);
  
      reader.readAsDataURL(this.blogImage);
    }
    else {
      data.image = '';
    }
  }

  saveDetails(form, data) {
    if (this.blogImage) {
      this.uploadBlogImage(form, this.blogImage).then(imageUrl => {
        data.image = imageUrl;
        this.uploadBlogData(form, data);
      });
    }
    else {
      this.uploadBlogData(form, data);
    }
  }

  uploadBlogImage(form, imageFile: File) {
    if (form.valid && imageFile) {
      const dotIndex = imageFile.name.lastIndexOf('.');
      const filename = imageFile.name.substr(0, dotIndex);
      const extension = imageFile.name.substr(dotIndex + 1);
      const fullname = `${filename}-${Date.now()}.${extension}`;

      const blogImageRef = this.storageRef.child(`blog/${fullname}`);
      return blogImageRef.put(this.blogImage).then(snapshot => {
        console.log(snapshot);
        return blogImageRef.getDownloadURL().then(url => {
          console.log(typeof url, url);
          return url;
        });
      });
    }
    else {
      return Promise.reject('Upload failed.');
    }
  }

  uploadBlogData(form, data) {
    if (form.valid) {
      const blogObj = {
        title: data.title,
        description: data.description,
        author: data.author,
        publishedDate: firebase.firestore.Timestamp.fromDate(moment(data.dateCtrlStr, 'YYYY-MM-DD').toDate()),
        image: data.image
      };

      if (data.id) {
        this.db.collection('blog').doc(data.id).set(blogObj, { merge: true }).then(() => {
          this.closeDetails();
          this.getBlogPosts();
        });
      }
      else {
        this.db.collection('blog').add(blogObj).then(() => {
          this.closeDetails();
          this.getBlogPosts();
        });
      }
    }
  }

  deleteDetails(event, data) {
    event.stopPropagation();
    this.db.collection('blog').doc(data.id).delete().then(() => {
      this.getBlogPosts();
    });
  }

}
