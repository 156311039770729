<div class="container">
    <div class="row align-items-center">
        <div class="col-lg-2 col-6 col-sm-4 col-md-4">
            <div class="single-partner-item">
                <img src="assets/img/partner/partner-img6.png" alt="image">
            </div>
        </div>

        <div class="col-lg-2 col-6 col-sm-4 col-md-4">
            <div class="single-partner-item">
                <img src="assets/img/partner/partner-img2.png" alt="image">
            </div>
        </div>

        <div class="col-lg-2 col-6 col-sm-4 col-md-4">
            <div class="single-partner-item">
                <img src="assets/img/partner/partner-img3.png" alt="image">
            </div>
        </div>

        <div class="col-lg-2 col-6 col-sm-4 col-md-4">
            <div class="single-partner-item">
                <img src="assets/img/partner/partner-img4.png" alt="image">
            </div>
        </div>

        <div class="col-lg-2 col-6 col-sm-4 col-md-4">
            <div class="single-partner-item">
                <img src="assets/img/partner/partner-img1.png" alt="image">
            </div>
        </div>

        <div class="col-lg-2 col-6 col-sm-4 col-md-4">
            <div class="single-partner-item">
                <img src="assets/img/partner/partner-img5.png" alt="image">
            </div>
        </div>
    </div>
</div>