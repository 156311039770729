<section class="page-title-area">
    <div class="container">
        <div class="page-title-content">
            <h1>Team</h1>
        </div>
    </div>

    <div class="shape2"><img src="assets/img/shape/shape2.png" alt="image"></div>
    <div class="shape3"><img src="assets/img/shape/shape3.png" alt="image"></div>
    <div class="shape5"><img src="assets/img/shape/shape5.png" alt="image"></div>
    <div class="shape6"><img src="assets/img/shape/shape6.png" alt="image"></div>
    <div class="shape7"><img src="assets/img/shape/shape7.png" alt="image"></div>
    <div class="shape8"><img src="assets/img/shape/shape8.png" alt="image"></div>
    <div class="lines">
        <div class="line"></div>
        <div class="line"></div>
        <div class="line"></div>
    </div>
</section>

<div *ngIf="loggedInUser === ''">
    <section class="team-area-two pt-100 pb-70">
        <div class="container">
            <div class="row align-items-center">
                <div class="col-lg-3 col-md-6 col-sm-6"  *ngFor="let teamPost of teamPosts">
                    <div class="single-team-member">
                        <div class="image">
                            <!-- <img src="assets/img/team/team-img1.jpg" alt="team-image"> -->
                            <img onError="this.src='assets/img/blog/blog-img1.jpg'" [src]="teamPost.image" />
                            <!-- <ul class="social-link">
                                <li><a href="#" class="d-block" target="_blank"><i class='bx bxl-facebook'></i></a></li>
                                <li><a href="#" class="d-block" target="_blank"><i class='bx bxl-twitter'></i></a></li>
                                <li><a href="#" class="d-block" target="_blank"><i class='bx bxl-instagram'></i></a>
                                </li>
                                <li><a href="#" class="d-block" target="_blank"><i class='bx bxl-linkedin'></i></a></li>
                            </ul> -->
                        </div>
                        <div class="content">
                            <h3 style="text-transform: capitalize;">{{teamPost.name}}</h3>
                            <span style="text-transform: uppercase;">{{teamPost.designation}}</span>
                            <!-- <i class="flaticon-share-icon"></i> -->
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </section>
</div>

<div *ngIf="loggedInUser && loggedInUser !== ''">
    <section class="blog-area ptb-100" *ngIf="!showTeamDetails">
        <div class="container">
            <div class="blog-buttons">
                <div class="row align-items-center">
                    <div class="col-lg-7 col-sm-7 col-md-7"></div>
                    <div class="col-lg-5 col-sm-5 col-md-5 text-right">
                        <button type="submit" (click)="addDetails()">Add Team</button>
                    </div>
                </div>
            </div>
            <form>
                <div class="blog-table table-responsive">
                    <table class="table table-bordered">
                        <thead>
                            <tr>
                                <th scope="col">Image</th>
                                <th scope="col">Name</th>
                                <th scope="col">Designation</th>
                                <th scope="col">Actions</th>
                            </tr>
                        </thead>

                        <tbody>
                            <tr *ngFor="let teamPost of teamPosts" (click)="showDetails(teamPost)">
                                <td class="product-thumbnail">
                                    <a routerLink="/single-products">
                                        <!-- <img src="assets/img/products/products-img1.jpg" alt="item"> -->
                                        <img onError="this.src='https://via.placeholder.com/100'"
                                            [src]="teamPost.image" />
                                    </a>
                                </td>

                                <td class="product-name">
                                    <a>{{teamPost.name}}</a>
                                </td>

                                
                                <td class="product-name">
                                    <a>{{teamPost.designation}}</a>
                                </td>

                                <td class="product-subtotal">
                                    <a class="remove pr-10" (click)="editDetails(teamPost)"><i
                                            class='bx bx-edit'></i></a>
                                    <a class="remove" (click)="deleteDetails($event, teamPost)"><i
                                            class='bx bx-trash'></i></a>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </form>
        </div>
    </section>

    
    <!-- More details -->
    <section class="products-details-area ptb-100" *ngIf="showTeamDetails && !editMode">
        <div class="container">
            <div class="blog-buttons">
                <div class="row align-items-center">
                    <div class="col-lg-7 col-sm-7 col-md-7"></div>
                    <div class="col-lg-5 col-sm-5 col-md-5 text-right">
                        <button type="submit" (click)="closeDetails()">Back</button>
                    </div>
                </div>
            </div>
            <div class="row align-items-center">
                <div class="col-lg-5 col-md-12">
                    <div class="products-details-image">
                        <a data-fancybox="gallery">
                            <img onError="this.src='assets/img/products/products-img1.jpg'"
                                [src]="selectedTeam.image" />
                        </a>
                    </div>
                </div>

                <div class="col-lg-7 col-md-12">
                    <div class="products-details-desc">
                        <h3>{{selectedTeam.name}}</h3>

                        <p>{{selectedTeam.designation}}</p>
                    </div>
                </div>
            </div>
        </div>
    </section>

    <!-- Add or Edit-->
    <section class="contact-area ptb-100" *ngIf="showTeamDetails && editMode">
        <div class="container">
            <form #teamForm="ngForm" (ngSubmit)="saveDetails(teamForm, selectedTeam)">
                <div class="row">
                    <div class="col-lg-6 col-md-12">
                        <div class="contact-image">
                            <input type="file" #fileUploadEl class="upload-image-hidden" (change)="getFile($event, selectedTeam)" accept=".jpg,.jpeg,.png" />
                            <img onError="this.src='assets/img/contact.png'" [src]="selectedTeam.image" name="image" (click)="fileUploadEl.click()" />
                        </div>
                    </div>

                    <div class="col-lg-6 col-md-12">
                        <div class="contact-form">
                            <div class="row">
                                <div class="col-lg-12 col-md-6">
                                    <div class="form-group">
                                        <label>Name: </label>
                                        <input type="text" name="title" class="form-control" id="title" required
                                            [(ngModel)]="selectedTeam.name" data-error="Please enter title"
                                            placeholder="Your Name">
                                    </div>
                                </div>

                                <div class="col-lg-12 col-md-12">
                                    <div class="form-group">
                                        <label>Designation: </label>
                                        <input type="text" name="designation" class="form-control" id="designation"
                                            required data-error="Please enter designation"
                                            [(ngModel)]="selectedTeam.designation" placeholder="Designation">
                                    </div>
                                </div>

                                <div class="col-lg-12 col-md-12">
                                    <button type="button" (click)="closeDetails()"
                                        class="cancel-btn mr-10">Cancel</button>
                                    <button type="submit" [disabled]="teamForm.invalid"
                                        class="default-btn">Save</button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </form>
        </div>
    </section>
</div>

