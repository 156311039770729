import { BrowserModule } from '@angular/platform-browser';
import { FormsModule } from '@angular/forms';
import { NgModule } from '@angular/core';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';

import { PreloaderComponent } from './components/common/preloader/preloader.component';
import { NavbarComponent } from './components/common/navbar/navbar.component';
import { FooterComponent } from './components/common/footer/footer.component';
import { LoginPageComponent } from './components/pages/login/login-page.component';
import { HomepageComponent } from './components/pages/homepage/homepage.component';
import { HomeAboutComponent } from './components/pages/homepage/home-about/home-about.component';
import { HomeServicesComponent } from './components/pages/homepage/home-services/home-services.component';
import { HomeMainBannerComponent } from './components/pages/homepage/home-main-banner/home-main-banner.component';
import { BlogComponent } from './components/common/blog/blog.component';
import { BoxesComponent } from './components/common/boxes/boxes.component';
import { FeedbackComponent } from './components/common/feedback/feedback.component';
import { PartnerComponent } from './components/common/partner/partner.component';
import { AboutPageComponent } from './components/pages/aboutpage/about-page.component';
import { ContactPageComponent } from './components/pages/contactpage/contact-page.component';
import { TeamComponent } from './components/pages/team/team.component';
import { TimesheetsComponent } from './components/pages/timesheets/timesheets.component';
import { LogComponent } from './components/pages/log/log.component';
import { JavaComponent } from './components/pages/javaui/javaui.component';
import { DevopsComponent } from './components/pages/devops/devops.component';
import { CareersComponent } from './components/pages/careers/careers.component';
import { BlogPageComponent } from './components/pages/blog/blog-page.component';
import { BlogDetailsPageComponent } from './components/pages/blog-details/blog-details-page.component';
import { QuillModule } from 'ngx-quill'

@NgModule({
  declarations: [
    AppComponent,
    PreloaderComponent,
    NavbarComponent,
    FooterComponent,
    LoginPageComponent,
    HomepageComponent,
    HomeAboutComponent,
    HomeServicesComponent,
    HomeMainBannerComponent,
    BlogComponent,
    BoxesComponent,
    FeedbackComponent,
    PartnerComponent,
    AboutPageComponent,
    TeamComponent,
    ContactPageComponent,
    TimesheetsComponent,
    LogComponent,
    JavaComponent,
    DevopsComponent,
    CareersComponent,
    BlogPageComponent,
    BlogDetailsPageComponent
  ],
  imports: [
    BrowserModule,
    FormsModule,
    AppRoutingModule,
    QuillModule.forRoot()
  ],
  providers: [],
  bootstrap: [AppComponent]
})
export class AppModule { }
