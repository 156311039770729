import { Component, OnInit } from '@angular/core';
import * as firebase from 'firebase/app';
import { AuthService } from '../../../services/auth.service';

@Component({
  selector: 'app-team',
  templateUrl: './team.component.html',
  styleUrls: ['./team.component.scss']
})
export class TeamComponent implements OnInit {
  db: firebase.firestore.Firestore;
  storageRef: firebase.storage.Reference;
  teamPosts: any[] = [];
  showTeamDetails = false;
  editMode = false;
  selectedTeam = null;
  loggedInUser = '';
  teamImage: File = null;
  constructor(private authService: AuthService) {
    this.db = firebase.firestore();
    this.storageRef = firebase.storage().ref();
  }

  ngOnInit(): void {
    this.loggedInUser = this.authService.userEmail;
    this.getTeamPosts();
  }

  addDetails() {
    this.showTeamDetails = true;
    this.editMode = true;
    this.selectedTeam = {
      name: '',
      designation: '',
      image: ''
    };
  }

  showDetails(data) {
    this.showTeamDetails = true;
    this.selectedTeam = data;
  }

  closeDetails() {
    this.showTeamDetails = false;
    this.editMode = false;
    this.selectedTeam = null;
    this.teamImage = null;
  }

  editDetails(data) {
    this.showTeamDetails = true;
    this.editMode = true;
    this.selectedTeam = { ...data };
  }

  deleteDetails(event, data) {
    event.stopPropagation();
    this.db.collection('team').doc(data.id).delete().then(() => {
      this.getTeamPosts();
    });
  }

  getTeamPosts() {
    this.teamPosts = [];
    this.db.collection('team').get().then(snapshot => {
      // console.log(snapshot.docs);
      snapshot.docs.forEach(doc => {
        // console.log(doc.data());
        const data = doc.data();
        data.id = doc.id;
        this.teamPosts.push(data);
      });
    });
  }

  getFile(event, data) {
    console.log(event.target.files);
    this.teamImage = event.target.files.length ? event.target.files[0] : null;

    // Preview image using base64 data url
    if (this.teamImage) {
      const reader = new FileReader();

      reader.addEventListener("load", () => {
        data.image = reader.result;
      }, false);

      reader.readAsDataURL(this.teamImage);
    }
    else {
      data.image = '';
    }
  }

  saveDetails(form, data) {
    if (this.teamImage) {
      this.uploadTeamImage(form, this.teamImage).then(imageUrl => {
        data.image = imageUrl;
        this.uploadTeamData(form, data);
      });
    }
    else {
      this.uploadTeamData(form, data);
    }
  }

  uploadTeamImage(form, imageFile: File) {
    if (form.valid && imageFile) {
      const dotIndex = imageFile.name.lastIndexOf('.');
      const filename = imageFile.name.substr(0, dotIndex);
      const extension = imageFile.name.substr(dotIndex + 1);
      const fullname = `${filename}-${Date.now()}.${extension}`;

      const teamImageRef = this.storageRef.child(`team/${fullname}`);
      return teamImageRef.put(this.teamImage).then(snapshot => {
        console.log(snapshot);
        return teamImageRef.getDownloadURL().then(url => {
          console.log(typeof url, url);
          return url;
        });
      });
    }
    else {
      return Promise.reject('Upload failed.');
    }
  }

  uploadTeamData(form, data) {
    if (form.valid) {
      const teamObj = {
        name: data.name,
        designation: data.designation,
        image: data.image
      };

      if (data.id) {
        this.db.collection('team').doc(data.id).set(teamObj, { merge: true }).then(() => {
          this.closeDetails();
          this.getTeamPosts();
        });
      }
      else {
        this.db.collection('team').add(teamObj).then(() => {
          this.closeDetails();
          this.getTeamPosts();
        });
      }
    }
  }

}
