import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import * as firebase from 'firebase/app';

@Injectable({ providedIn: 'root' })
export class AuthService {
  private email = '';

  constructor(private router: Router) {
    this.email = localStorage.getItem('userEmail') || '';

    firebase.auth().onAuthStateChanged((user) => {
      console.log('onAuthStateChanged', user);
      if (user) {
        // User is signed in.
        if (this.email !== user.email) {
          localStorage.setItem('userEmail', user.email);
          this.email = user.email;
          this.router.navigateByUrl('/blog');
        }
      } else {
        // No user is signed in.
        localStorage.setItem('userEmail', '');
        this.email = '';
        // this.router.navigateByUrl('/login');
      }
    });
  }

  public get userEmail(): string {
    return this.email;
  }

  // setUserEmail(email) {
  //   this.email = email;
  // }

  login(email, password) {
    return firebase.auth().signInWithEmailAndPassword(email, password);
  }

  logout() {
    firebase.auth().signOut()
      .then(() => {
        this.router.navigateByUrl('/login');
      })
      .catch((error) => {
        console.log(error);
      });
  }
}
