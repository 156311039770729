<section class="page-title-area">
    <div class="container">
        <div class="page-title-content">
            <h1>Java/UI Full Stack Web Development</h1>
        </div>
    </div>

    <div class="shape2"><img src="assets/img/shape/shape2.png" alt="image"></div>
    <div class="shape3"><img src="assets/img/shape/shape3.png" alt="image"></div>
    <div class="shape5"><img src="assets/img/shape/shape5.png" alt="image"></div>
    <div class="shape6"><img src="assets/img/shape/shape6.png" alt="image"></div>
    <div class="shape7"><img src="assets/img/shape/shape7.png" alt="image"></div>
    <div class="shape8"><img src="assets/img/shape/shape8.png" alt="image"></div>
    <div class="lines">
        <div class="line"></div>
        <div class="line"></div>
        <div class="line"></div>
    </div>
</section>

<section class="overview-area ptb-100">
    <div class="container">
        <div class="overview-box">
            <div class="overview-content">
                <div class="content right-content"> 
                    <h2>Java Development</h2>
                    <ul class="features-list">
                        <li><span><i class='bx bx-check'></i> Java 8, Lambdas and Streams                        </span></li>
                        <li><span><i class='bx bx-check'></i> Tomcat, Servlets and JSP's                        </span></li>
                        <li><span><i class='bx bx-check'></i> JDBC and JPA</span></li>
                        <li><span><i class='bx bx-check'></i> Eclipse/Intellij                        </span></li>
                    </ul>
                </div>
            </div>
            <div class="overview-content">
                <div class="content right-content"> 
                    <h2>Spring Based Development</h2>
                    <ul class="features-list">
                        <li><span><i class='bx bx-check'></i> Spring MVC, Thymeleaf, REST API</span></li>
                        <li><span><i class='bx bx-check'></i> Spring Batch, Mobile</span></li>
                        <li><span><i class='bx bx-check'></i> Spring Boot, JMS</span></li>
                        <li><span><i class='bx bx-check'></i> Spring WebFlux</span></li>
                    </ul>
                </div>
            </div>
            <div class="overview-content" style="padding-top:60px;">
                <div class="content right-content"> 
                    <h2>Angular Development</h2>
                    <ul class="features-list">
                        <li><span><i class='bx bx-check'></i> Angular 2,3,4,5,6,7,8,9,10</span></li>
                        <li><span><i class='bx bx-check'></i> Routers, Components, HTTP Modules, RxJS, NgRx</span></li>

                    </ul>
                </div>
            </div>

            <div class="overview-content" style="padding-top:60px;">
                <div class="content right-content"> 
                    <h2>React</h2>
                    <ul class="features-list">
                        <li><span><i class='bx bx-check'></i> React JS, React Native</span></li>
                        <li><span><i class='bx bx-check'></i> Redux, Flux state management</span></li>
                    
                    </ul>
                </div>
            </div>

            <div class="overview-content" style="padding-top:60px;">
                <div class="content right-content"> 
                    <h2>Cloud</h2>
                    <ul class="features-list">
                        <li><span><i class='bx bx-check'></i> Microsoft Azure - Azure DevOps</span></li>
                        <li><span><i class='bx bx-check'></i> Cloud Foundry, OpenShift</span></li>
                        <li><span><i class='bx bx-check'></i> Google Cloud</span></li>
                        <li><span><i class='bx bx-check'></i> AWS</span></li>
                    </ul>
                </div>
            </div>

            <div class="overview-content" style="padding-top:60px;">
                <div class="content right-content"> 
                    <h2>Build and Deployment </h2>
                    <ul class="features-list">
                        <li><span><i class='bx bx-check'></i> Github/SVN</span></li>
                        <li><span><i class='bx bx-check'></i> Jenkins, Bamboo</span></li>
                        <li><span><i class='bx bx-check'></i> Nexus                        </span></li>
                        <li><span><i class='bx bx-check'></i> UDeploy</span></li>
                    </ul>
                </div>
            </div>

            <div class="overview-content" style="padding-top:60px;">
                <div class="content right-content"> 
                    <h2>MicroServices Development </h2>
                    <ul class="features-list">
                        <li><span><i class='bx bx-check'></i> Spring Boot with JPA</span></li>
                        <li><span><i class='bx bx-check'></i> Spring AMQP event mechanism</span></li>
                       
                    </ul>
                </div>
            </div>

            <div class="overview-content" style="padding-top:60px;">
                <div class="content right-content"> 
                    <h2>Other Popular JS </h2>
                    <ul class="features-list">
                        <li><span><i class='bx bx-check'></i> Vue JS</span></li>
                        <li><span><i class='bx bx-check'></i> Backbone JS              </span></li>
                       
                    </ul>
                </div>
            </div>

            <div class="overview-content" style="padding-top:60px;">
                <div class="content right-content"> 
                    <h2>Build and Deployment </h2>
                    <ul class="features-list">
                        <li><span><i class='bx bx-check'></i> Visual Code</span></li>
                        <li><span><i class='bx bx-check'></i> Node, npm</span></li>
                        <li><span><i class='bx bx-check'></i> Nginx</span></li>
                        <li><span><i class='bx bx-check'></i> AWS & Azure</span></li>
                    </ul>
                </div>
            </div>

            <div class="overview-content" style="padding-top:60px;">
                <div class="content right-content"> 
                    <h2>Cloud Development</h2>
                    <ul class="features-list">
                        <li><span><i class='bx bx-check'></i> 
                            Azure Services - App Service, Blob Storage, Functions, Cosmos DB, Event Horizon</span></li>
                        <li><span><i class='bx bx-check'></i> AWS Services - S3, EC2, Elastic Bean Stack, Lambda Functions</span></li>
                       
                    </ul>
                </div>
            </div>

        </div>
    </div>
</section>
