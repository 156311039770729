<section class="page-title-area">
    <div class="container">
        <div class="page-title-content">
            <h1>Log Requests</h1>
        </div>
    </div>

    <div class="shape2"><img src="assets/img/shape/shape2.png" alt="image"></div>
    <div class="shape3"><img src="assets/img/shape/shape3.png" alt="image"></div>
    <div class="shape5"><img src="assets/img/shape/shape5.png" alt="image"></div>
    <div class="shape6"><img src="assets/img/shape/shape6.png" alt="image"></div>
    <div class="shape7"><img src="assets/img/shape/shape7.png" alt="image"></div>
    <div class="shape8"><img src="assets/img/shape/shape8.png" alt="image"></div>
    <div class="lines">
        <div class="line"></div>
        <div class="line"></div>
        <div class="line"></div>
    </div>
</section>

<section class="about-area ptb-40">
    <div class="container-fluid" style="max-width:1024px;margin:0 auto;">
        <div class="row">
            <div class="col-lg-12 col-md-12">
                <div class="about-content">
                    <div class="content">
                        <iframe class="airtable-embed airtable-dynamic-height" src="https://airtable.com/embed/shrZHVCCGYCIdPdZ6?backgroundColor=gray" frameborder="0" onmousewheel="" width="100%" height="1022" style="background: transparent; border: 1px solid #ccc;"></iframe>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>