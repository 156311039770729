<section class="page-title-area">
    <div class="container">
        <div class="page-title-content">
            <h1>Job Opportunities</h1>
        </div>
    </div>

    <div class="shape2"><img src="assets/img/shape/shape2.png" alt="image"></div>
    <div class="shape3"><img src="assets/img/shape/shape3.png" alt="image"></div>
    <div class="shape5"><img src="assets/img/shape/shape5.png" alt="image"></div>
    <div class="shape6"><img src="assets/img/shape/shape6.png" alt="image"></div>
    <div class="shape7"><img src="assets/img/shape/shape7.png" alt="image"></div>
    <div class="shape8"><img src="assets/img/shape/shape8.png" alt="image"></div>
    <div class="lines">
        <div class="line"></div>
        <div class="line"></div>
        <div class="line"></div>
    </div>
</section>

<!--Before login template -->
<section class="overview-area ptb-100" *ngIf="loggedInUser === ''">
    <div class="container">
        <div class="accordion" *ngFor="let job of jobs">
            <div class="card" style="margin-bottom: 15px;">
                <div class="card-header" id="headingOne" style="background-color: white">
                    <h2 class="mb-0">
                        <button class="btn" type="button" data-toggle="collapse" data-target="#collapseOne"
                            aria-expanded="true" aria-controls="collapseOne" style="font-size:30px;font-family:Nunito">
                            {{job.title}}
                        </button>
                    </h2>
                </div>

                <div id="collapseOne" class="collapse show" aria-labelledby="headingOne">
                    <div class="card-body">
                        <!-- <p class="font_8" style="font-size:15px; line-height:1.35em;">Job Duties Includes</p> -->
                        <!-- <ul class="font_8" style="color:black"> -->
                        <p class="font_8">
                            <span style="font-weight: 700;padding-right: 10px;">Description:</span>
                            <span  [innerHTML]="job.description"></span>
                        </p>
                        <p class="font_8">
                            <span style="font-weight: 700;padding-right: 10px;">Location:</span>
                            {{job.location}}
                        </p>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>


<!-- After login-->

<div *ngIf="loggedInUser && loggedInUser !== ''">
    <section class="overview-area ptb-100" *ngIf="!showJobDetails">
        <div class="container">
            <div class="career-buttons">
                <div class="row align-items-center">
                    <div class="col-lg-7 col-sm-7 col-md-7"></div>
                    <div class="col-lg-5 col-sm-5 col-md-5 text-right">
                        <button type="submit" (click)="addDetails()">Add Job</button>
                    </div>
                </div>
            </div>
            <div class="accordion" id="accordionExample">
                <div *ngFor="let job of jobs" class="card"
                    style="margin-bottom: 15px; border: 1px solid rgba(0, 0, 0, 0.125);">
                    <div class="card-header" id="headingOne" style="background-color: white" (click)="showDetails(job)">
                        <h2 class="mb-0" style="display: flex;">
                            <button class="btn" type="button" data-toggle="collapse" data-target="#collapseOne"
                                aria-expanded="true" aria-controls="collapseOne"
                                style="font-size:30px;font-family:Nunito;flex: 1;text-align: left;padding-left: 0;">
                                {{job.title}}
                            </button>
                            <div class="pt-20" style=" color: red;
                        font-size: 20px; ">
                                <span (click)="editDetails(job)" style="padding-right: 10px;"><i
                                        class='bx bx-edit'></i></span>
                                <span (click)="deleteDetails($event, job)" style="padding-left: 10px;"><i
                                        class='bx bx-trash'></i></span>
                            </div>
                        </h2>
                    </div>

                    <div id="collapseOne" class="collapse show" aria-labelledby="headingOne"
                        data-parent="#accordionExample">
                        <div class="card-body">
                            <p><span style="font-weight: 700;padding-right: 10px;">Description:</span>
                                <span  [innerHTML]="job.description"></span></p>
                            <p><span style="font-weight: 700;padding-right: 10px;">Location:</span> {{job.location}}</p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </section>


    <!-- Add or Edit-->
    <section class="overview-area ptb-100" *ngIf="showJobDetails && editMode">
        <div class="container">
            <form #jobForm="ngForm" (ngSubmit)="saveDetails(jobForm, selectedJob)">
                <div class="row">
                    <div class="col-lg-12 col-md-12">
                        <div class="contact-form">
                            <div class="row">
                                <div class="col-lg-12 col-md-6">
                                    <div class="form-group">
                                        <label>Title: </label>
                                        <input type="text" name="title" class="form-control" id="title" required
                                        [ngModelOptions]="{standalone: true}" [(ngModel)]="selectedJob.title" data-error="Please enter title"
                                            placeholder="Job Title">
                                    </div>
                                </div>

                                <!-- <div class="col-lg-12 col-md-6">
                                    <div class="form-group">
                                        <label>Description: </label>
                                        <input type="text" name="description" class="form-control" id="description"
                                            required data-error="Please enter Date"
                                            [ngModelOptions]="{standalone: true}" [(ngModel)]="selectedJob.description" placeholder="Enter Description">
                                    </div>
                                </div> -->
                                <div class="col-lg-12 col-md-12">
                                    <div class="form-group">
                                        <label>Location: </label>
                                        <input type="text" name="location" class="form-control" id="location" required
                                        [ngModelOptions]="{standalone: true}"  data-error="Please enter location" [(ngModel)]="selectedJob.location"
                                            placeholder="Enter Location">
                                    </div>
                                </div>
                                <div class="col-lg-12 col-md-6" style="height:200px;">
                                    <div class="form-group">
                                        <label>Description: </label>
                                        <quill-editor [ngModelOptions]="{standalone: true}" [(ngModel)]="selectedJob.description" #modelRef="ngModel"></quill-editor>
                                    </div>
                                </div> 

                               

                                <div class="col-lg-12 col-md-12">
                                    <button type="button" (click)="closeDetails()"
                                        class="cancel-btn mr-10">Cancel</button>
                                    <button type="submit" [disabled]="jobForm.invalid" class="default-btn">Save</button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </form>
        </div>
    </section>

    <!-- More details -->
    <section class="overview-area ptb-100" *ngIf="showJobDetails && !editMode">
        <div class="container">
            <div class="career-buttons">
                <div class="row align-items-center">
                    <div class="col-lg-7 col-sm-7 col-md-7"></div>
                    <div class="col-lg-5 col-sm-5 col-md-5 text-right">
                        <button type="submit" (click)="closeDetails()">Back</button>
                    </div>
                </div>
            </div>
            <div class="row align-items-center">
                <div class="col-lg-12 col-md-12">
                    <div class="products-details-desc">
                        <p><span style="font-weight: 700;padding-right: 10px;font-size: 20px;">Title:</span>
                            {{selectedJob.title}}</p>
                        <p><span style="font-weight: 700;padding-right: 10px;font-size: 20px;">Description:</span>
                            {{selectedJob.description}}</p>
                        <p><span style="font-weight: 700;padding-right: 10px;font-size: 20px;">Location:</span>
                            {{selectedJob.location}}</p>
                    </div>
                </div>
            </div>
        </div>
    </section>
</div>