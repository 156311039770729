<app-home-main-banner></app-home-main-banner>

<section class="boxes-area pb-70">
    <app-boxes></app-boxes>
</section>

<app-home-about></app-home-about>

<app-home-services></app-home-services>

<section class="feedback-area ptb-100">
    <app-feedback></app-feedback>

    <div class="shape16"><img src="assets/img/shape/shape16.png" alt="image"></div>
</section>


<!--<section class="blog-area bg-f9f9f9 pt-100 pb-70">
    <app-blog></app-blog>
</section>-->


<!-- <section class="feedback-area ptb-70">
    <app-partner></app-partner>
</section> -->

<section class="blog-area bg-f9f9f9 ptb-70">
   
</section>