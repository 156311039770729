<section class="page-title-area">
    <div class="container">
        <div class="page-title-content">
            <h1>WELCOME TO TEKSPIKES</h1>
        </div>
    </div>

    <div class="shape2"><img src="assets/img/shape/shape2.png" alt="image"></div>
    <div class="shape3"><img src="assets/img/shape/shape3.png" alt="image"></div>
    <div class="shape5"><img src="assets/img/shape/shape5.png" alt="image"></div>
    <div class="shape6"><img src="assets/img/shape/shape6.png" alt="image"></div>
    <div class="shape7"><img src="assets/img/shape/shape7.png" alt="image"></div>
    <div class="shape8"><img src="assets/img/shape/shape8.png" alt="image"></div>
    <div class="lines">
        <div class="line"></div>
        <div class="line"></div>
        <div class="line"></div>
    </div>
</section>

<section class="about-area ptb-40">
    <div class="container-fluid" style="max-width:1024px;margin:0 auto;">
        <div class="row">
            <div class="col-lg-12 col-md-12">
                <div class="about-content">
                    <div class="content">
                        <h2>About Us</h2>
                        <p>Tek Spikes is a solution provider company involved in the business of providing IT solutions to companies in all business domains on IT, Financial,Telecom, Health, Retail, Manufacturing, Insurance and Media. We have a pool of talent to meet the requirements of our clients within the expected. Our sphere of operations includes application Lifecycle Management, Infrastructure Lifecycle Management and Product Lifecycle Management.</p>
                       
                    </div>
                </div>
            </div>
        </div>
        <div class="row">
            <div class="col-lg-12 col-md-12">
                <div class="about-content">
                    <div class="content">
                        <h2>Who We Are</h2>
                        <p>Tek Spikes is a technology services and outsourcing company. Combining unparalleled experience, comprehensive capabilities across all industries and business functions, and extensive research on the world’s most successful companies, Tek Spikes collaborates with clients to help them become high-performance businesses and governments.</p>
                        <p>Our "High performance business" strategy builds on our expertise in consulting, technology and outsourcing to help clients perform at the highest levels so they can create sustainable value for their customers and shareholders. Using our industry knowledge, service-offering expertise and technology capabilities, we identify new business and technology trends and develop solutions to help clients around the world:</p>
                        <ul class="font_8">
                            <li>
                            <p class="font_8">Enter new markets.&nbsp;</p>
                            </li>
                            <li>
                            <p class="font_8">Increase revenues in existing markets.&nbsp;</p>
                            </li>
                            <li>
                            <p class="font_8">Improve operational performance.&nbsp;</p>
                            </li>
                            <li>
                            <p class="font_8">Deliver their products and services more effectively and efficiently</p>
                            </li>
                        </ul>
                    </div>
                </div>
            </div>

            <div class="col-lg-12 col-md-12">
                <div class="about-content">
                    <div class="content">
                        <h2>Why TEK SPIKES</h2>
                        <span class="sub-title">Core Values & Beliefs</span>
                        <p>We are convinced that the key to creating a truly great organization is an intense focus on the values that guide its people's actions. These are Tek Spikes "Core Values and Beliefs".</p>
                        <ul class="font_8">
                            <li>
                            <p class="font_8">&nbsp;Client Focused Delivery</p>
                            </li>
                            <li>
                            <p class="font_8">&nbsp;Leadership</p>
                            </li>
                            <li>
                            <p class="font_8">&nbsp;Relationships</p>
                            </li>
                            <li>
                            <p class="font_8">&nbsp;Innovation</p>
                            </li>
                            <li>
                            <p class="font_8">&nbsp;Peoples Growth</p>
                            </li>
                            <li>
                            <p class="font_8">&nbsp;Openness<br>
                            &nbsp;</p>
                            </li>
                        </ul>
                    </div>
                </div>
            </div>

            <div class="col-lg-12 col-md-12">
                <div class="about-content">
                    <div class="content">
                        <h2>Our Mission</h2>
                        <span class="sub-title">Giving new definition to customer service through innovation</span>
                        <p>A truly global IT Services and Management Consulting company founded by consulting experts to revolutionize the services industry by their innovative ideas. Our innovative methodology and process, constantly delivers improved results for our customers to stay ahead of their competitors. As a professionally managed company, we provide best of breed service to our customers which transforms their business. Deep industry knowledge and exceptional technical expertise combined with best business ethics makes Tek Spikes the global leader in IT Services and Management Consulting.</p>
                    </div>
                </div>
            </div>

            <div class="col-lg-12 col-md-12">
                <div class="about-content">
                    <div class="content">
                        <div style="margin-top:20px;">
                            <h2>Leadership Excellence</h2>
                            <span class="sub-title">Results Oriented</span>
                            <p>Tek Spikes  works towards achieving results that excel Tek Spikes the expectation of stakeholders and always maintains the growth.</p>
                        </div>
                        <div style="margin-top:20px;">
                            <span class="sub-title">Customer Focus</span>
                            <p>Tek Spikes creates excellence in customer values through high quality service for true value of money.</p>
                        </div>
                        <div style="margin-top:20px;">
                            <span class="sub-title">Leadership</span>
                            <p>Global vision and purpose makes our leadership to take Tek Spikes to the future.</p>
                        </div>
                        <div style="margin-top:20px;">
                            <span class="sub-title">Management</span>
                            <p>Professionally managing the organization through well-defined scientific processes; and well-proven systems and facts.</p>
                        </div>
                        <div style="margin-top:20px;">
                            <span class="sub-title">People Development</span>
                            <p>Tek Spikes believes in creating value for the organization through continuous development of the people since people are the organizations assets.</p>
                        </div>
                        <div style="margin-top:20px;">
                            <span class="sub-title">Innovation</span>
                            <p>Continuous learning, never give up attitude and the desire to excel has made us to always deliver innovative solutions to our clients.</p>
                        </div>
                        <div style="margin-top:20px;">
                            <span class="sub-title">Our Vision</span>
                            <ul class="font_8">
                                <li>
                                <p class="font_8">&nbsp;To deliver innovative and effective technology solutions that makes a difference in the life of our clients business and the lives of their customers.&nbsp;</p>
                                </li>
                                <li>
                                <p class="font_8">&nbsp;To enable our employees, partners and customers to achieve their professional goals and aspirations.</p>
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>

        </div>
    </div>
</section>