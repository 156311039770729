<div class="{{navbarClass}}">
    <div class="plor-responsive-nav">
        <div class="container-fluid">
            <div class="plor-responsive-menu">
                <div class="logo">
                    <a routerLink="/">
                        <img src="assets/img/logo_dark.webp" alt="logo">
                    </a>
                </div>
            </div>
        </div>
    </div>

    <div class="plor-nav">
        <div class="{{containerClass}}">
            <nav class="navbar navbar-expand-md navbar-light">
                <a class="navbar-brand" routerLink="/">
                    <img src="assets/img/logo_dark.webp" alt="logo">
                </a>

                <div class="collapse navbar-collapse mean-menu">
                    <ul class="navbar-nav">
                        <li class="nav-item"><a routerLink="/" class="nav-link"
                            routerLinkActive="active" [routerLinkActiveOptions]="{exact: true}">Home</a></li>
                        <li class="nav-item"><a routerLink="/about-us" class="nav-link"
                            routerLinkActive="active" [routerLinkActiveOptions]="{exact: true}">About</a></li>

                        <li class="nav-item"><a href="javascript:void(0)" class="nav-link">Services <i
                                    class='bx bx-chevron-down'></i></a>
                            <ul class="dropdown-menu">
                                <li class="nav-item"><a routerLink="/javaandui" class="nav-link"
                                        routerLinkActive="active" [routerLinkActiveOptions]="{exact: true}">Java & UI
                                        Development</a></li>

                                <li class="nav-item"><a routerLink="/devopsandcloud" class="nav-link"
                                        routerLinkActive="active" [routerLinkActiveOptions]="{exact: true}">DevOps &
                                        Cloud Services</a></li>

                            </ul>
                        </li>
                        <li class="nav-item"><a href="javascript:void(0)" class="nav-link">Tools <i
                                    class='bx bx-chevron-down'></i></a>
                            <ul class="dropdown-menu">
                               <!-- <li class="nav-item"><a routerLink="/services-1" class="nav-link"
                                        routerLinkActive="active" [routerLinkActiveOptions]="{exact: true}">Mail</a>
                                </li>

                                <li class="nav-item"><a href="https://tekspikes.slack.com/" target="_blank">Slack</a>
                                </li>-->

                                <li class="nav-item"><a href="https://everck.com/#/" target="_blank">Timesheets</a>
                                </li>
                                <!--<li class="nav-item"><a routerLink="/log" class="nav-link"
                                        routerLinkActive="active" [routerLinkActiveOptions]="{exact: true}">Log
                                        Request</a></li>

                                <li class="nav-item"><a routerLink="/timesheets" class="nav-link"
                                        routerLinkActive="active"
                                        [routerLinkActiveOptions]="{exact: true}">Timesheets</a></li>-->
                            </ul>
                        </li>

                        <li class="nav-item"><a routerLink="/careers" class="nav-link" routerLinkActive="active"
                            [routerLinkActiveOptions]="{exact: true}">Careers</a></li>
                        <li class="nav-item" *ngIf="loggedInUser && loggedInUser !== ''"><a routerLink="/team" class="nav-link" routerLinkActive="active"
                            [routerLinkActiveOptions]="{exact: true}">Team</a></li>
                        <li class="nav-item" *ngIf="loggedInUser && loggedInUser !== ''"><a routerLink="/blog" class="nav-link" routerLinkActive="active"
                                [routerLinkActiveOptions]="{exact: true}">Blog</a></li>
                        <li class="nav-item"><a routerLink="/contact" class="nav-link" routerLinkActive="active"
                                [routerLinkActiveOptions]="{exact: true}">Contact</a></li>
                        <li class="nav-item" *ngIf="loggedInUser && loggedInUser !== ''"><a href="#" (click)="logoutUser($event)" class="nav-link">Logout</a>
                        </li>
                    </ul>
                </div>
            </nav>
        </div>
    </div>
</div>