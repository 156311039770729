import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { LoginPageComponent } from './components/pages/login/login-page.component';
import { HomepageComponent } from './components/pages/homepage/homepage.component';
import { AboutPageComponent } from './components/pages/aboutpage/about-page.component';
import { ContactPageComponent } from './components/pages/contactpage/contact-page.component';
import { TeamComponent } from './components/pages/team/team.component';
import { TimesheetsComponent } from './components/pages/timesheets/timesheets.component';
import { LogComponent } from './components/pages/log/log.component';
import { JavaComponent } from './components/pages/javaui/javaui.component';
import { DevopsComponent } from './components/pages/devops/devops.component';
import { CareersComponent } from './components/pages/careers/careers.component';
import { BlogPageComponent } from './components/pages/blog/blog-page.component';
import { BlogDetailsPageComponent } from './components/pages/blog-details/blog-details-page.component';
import { AuthGuard } from './guards/auth.guard';

const routes: Routes = [
   {path: '', component: HomepageComponent},
 // {path: '', redirectTo: '/home', pathMatch: 'full'},
  {path: 'login', component: LoginPageComponent},
  {path: 'about-us', component: AboutPageComponent},
  {path: 'contact', component: ContactPageComponent},
  {path: 'team', component: TeamComponent},
  {path: 'log', component: LogComponent},
  {path: 'blog', component: BlogPageComponent}, //  canActivate: [AuthGuard]
  {path: 'careers', component: CareersComponent}, //, canActivate: [AuthGuard]
  {path: 'javaandui', component: JavaComponent},
  {path: 'blogdetails/:id', component: BlogDetailsPageComponent},
  {path: 'devopsandcloud', component: DevopsComponent},
  {path: 'timesheets', component: TimesheetsComponent},
];

@NgModule({
  imports: [RouterModule.forRoot(routes, { relativeLinkResolution: 'legacy' })],
  exports: [RouterModule]
})
export class AppRoutingModule { }
