import { Component, OnInit } from '@angular/core';
import { Router, NavigationEnd } from '@angular/router';
import { Location, LocationStrategy, PathLocationStrategy } from '@angular/common';
import * as firebase from 'firebase/app';
import { AuthService } from 'src/app/services/auth.service';

@Component({
    selector: 'app-navbar',
    templateUrl: './navbar.component.html',
    styleUrls: ['./navbar.component.scss'],
    providers: [
        Location, {
            provide: LocationStrategy,
            useClass: PathLocationStrategy
        }
    ]
})
export class NavbarComponent implements OnInit {

    location: any;
    navbarClass: any;
    containerClass: any;
    loggedInUser = '';

    constructor(
        private router: Router,
        private authService: AuthService,
        location: Location
    ) {
        this.router.events
        .subscribe((event) => {
            if ( event instanceof NavigationEnd ) {
                this.location = this.router.url;
                this.navbarClass = 'navbar-area navbar-style-two';
                this.containerClass = 'container-fluid';
            }
        });
    }

    ngOnInit(): void {
        this.loggedInUser = this.authService.userEmail;
    }

    ngDoCheck() {
        this.loggedInUser = this.authService.userEmail;
    }

    logoutUser(event) {
        event.preventDefault();
        this.authService.logout();
    }

}