<!-- Start Footer Area -->
<footer class="footer-area">
    <div class="container">

        <div class="footer-bottom-area">
            <div class="row align-items-center">
                <div class="col-lg-6 col-md-6">
                    <p *ngFor="let Text of copyrightText;"><i class='bx bx-copyright'></i>{{Text.text}} <a target="_blank" href="{{Text.link}}">{{Text.linkText}}</a></p>
                </div>

                <!-- <div class="col-lg-6 col-md-6">
                    <ul>
                        <li><a routerLink="privacy-policy">Privacy Policy</a></li>
                        <li><a routerLink="terms-of-service">Terms & Conditions</a></li>
                    </ul>
                </div> -->
            </div>
        </div>
    </div>

    <div class="shape16"><img src="assets/img/shape/shape16.png" alt="image"></div>
</footer>
<!-- End Footer Area -->

<div class="go-top"><i class='bx bx-up-arrow-alt'></i></div>