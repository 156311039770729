<section class="home-wrapper-area">
    <div class="container-fluid">
        <div class="home-slides owl-carousel owl-theme">
            <div class="single-banner-item">
                <div class="row align-items-center">
                    <div class="col-lg-6 col-md-12">
                        <div class="banner-content">
                            <span class="sub-title">Growth Your Business</span>
                            <h1>We Provide Best Java Solutions</h1>
                            <p>Custom Software Devlopment on Java.</p>
                            <!-- <div class="btn-box">
                                <div class="d-flex align-items-center">
                                    <a routerLink="/contact" class="default-btn">Get Started</a>
                                    <a href="https://www.youtube.com/watch?v=Y5KCDWi7h9o" class="video-btn popup-youtube"><i class="flaticon-play-button"></i> Watch Video</a>
                                </div>
                            </div> -->
                        </div>
                    </div>

                    <div class="col-lg-6 col-md-12">
                        <div class="banner-image">
                            <img src="assets/img/banner-img1.jpg" alt="image">
                        </div>
                    </div>
                </div>
            </div>

            <div class="single-banner-item">
                <div class="row align-items-center">
                    <div class="col-lg-6 col-md-12">
                        <div class="banner-content">
                            <span class="sub-title">We Shape Your Business by</span>
                            <h1>UI/UX Solutions</h1>
                            <p>The UI/UX Agency You've Been Looking For!.</p>
                        </div>
                    </div>

                    <div class="col-lg-6 col-md-12">
                        <div class="banner-image">
                            <img src="assets/img/banner-img2.jpg" alt="image">
                        </div>
                    </div>
                </div>
            </div>

            <div class="single-banner-item">
                <div class="row align-items-center">
                    <div class="col-lg-6 col-md-12">
                        <div class="banner-content">
                            <span class="sub-title">We Shape Your Business by</span>
                            <h1>Devops Solutions</h1>
                        </div>
                    </div>

                    <div class="col-lg-6 col-md-12">
                        <div class="banner-image">
                            <img src="assets/img/banner-img3.jpg" alt="image">
                        </div>
                    </div>
                </div>
            </div>

        </div>
    </div>

    <div class="shape1"><img src="assets/img/shape/shape1.png" alt="image"></div>
    <div class="shape2"><img src="assets/img/shape/shape2.png" alt="image"></div>
    <div class="shape3"><img src="assets/img/shape/shape3.png" alt="image"></div>
    <div class="shape4"><img src="assets/img/shape/shape4.png" alt="image"></div>
    <div class="shape5"><img src="assets/img/shape/shape5.png" alt="image"></div>
    <div class="shape6"><img src="assets/img/shape/shape6.png" alt="image"></div>
    <div class="shape7"><img src="assets/img/shape/shape7.png" alt="image"></div>
    <div class="shape8"><img src="assets/img/shape/shape8.png" alt="image"></div>
    <div class="shape13"><img src="assets/img/shape/shape13.png" alt="image"></div>
    <div class="shape14"><img src="assets/img/shape/shape14.png" alt="image"></div>
</section>