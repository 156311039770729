<section class="page-title-area">
    <div class="container">
        <div class="page-title-content">
            <h1>DevOps & Cloud Services</h1>
        </div>
    </div>

    <div class="shape2"><img src="assets/img/shape/shape2.png" alt="image"></div>
    <div class="shape3"><img src="assets/img/shape/shape3.png" alt="image"></div>
    <div class="shape5"><img src="assets/img/shape/shape5.png" alt="image"></div>
    <div class="shape6"><img src="assets/img/shape/shape6.png" alt="image"></div>
    <div class="shape7"><img src="assets/img/shape/shape7.png" alt="image"></div>
    <div class="shape8"><img src="assets/img/shape/shape8.png" alt="image"></div>
    <div class="lines">
        <div class="line"></div>
        <div class="line"></div>
        <div class="line"></div>
    </div>
</section>

<section class="overview-area ptb-100">
    <div class="container">
        <div class="overview-box">
            <div class="overview-content">
                <div class="content right-content"> 
                    <h2>Source Code Management</h2>
                    <ul class="features-list">
                        <li><span><i class='bx bx-check'></i> Github, Bitbucket, Gitlab, SVN</span></li>
                        <li><span><i class='bx bx-check'></i> Source Tree</span></li>
                    </ul>
                </div>
            </div>
            <div class="overview-content">
                <div class="content right-content"> 
                    <h2>Continuous Integration</h2>
                    <ul class="features-list">
                        <li><span><i class='bx bx-check'></i> Puppet, Chef, Ansible</span></li>
                        <li><span><i class='bx bx-check'></i> Groovy Scripting</span></li>
                    </ul>
                </div>
            </div>
            <div class="overview-content" style="padding-top:60px;">
                <div class="content right-content"> 
                    <h2>Build Management</h2>
                    <ul class="features-list">
                        <li><span><i class='bx bx-check'></i> Maven, Gradle</span></li>
                        <li><span><i class='bx bx-check'></i> Jenkins, Bamboo, Circle CI</span></li>
                        <li><span><i class='bx bx-check'></i> Azure DevOps Services</span></li>
                    </ul>
                </div>
            </div>

            <div class="overview-content" style="padding-top:60px;">
                <div class="content right-content"> 
                    <h2>Pipeline Automation</h2>
                    <ul class="features-list">
                        <li><span><i class='bx bx-check'></i> Perl, Python, Bash, Ruby</span></li>
                        <li><span><i class='bx bx-check'></i> JSON, YAML</span></li>
                        <li><span><i class='bx bx-check'></i> Shell</span></li>
                    </ul>
                </div>
            </div>

            <div class="overview-content" style="padding-top:60px;">
                <div class="content right-content"> 
                    <h2>Cloud</h2>
                    <ul class="features-list">
                        <li><span><i class='bx bx-check'></i> Microsoft Azure - Azure DevOps</span></li>
                        <li><span><i class='bx bx-check'></i> Cloud Foundry, OpenShift</span></li>
                        <li><span><i class='bx bx-check'></i> Google Cloud</span></li>
                        <li><span><i class='bx bx-check'></i> AWS</span></li>
                    </ul>
                </div>
            </div>

            <div class="overview-content" style="padding-top:60px;">
                <div class="content right-content"> 
                    <h2>Monitoring​ </h2>
                    <ul class="features-list">
                        <li><span><i class='bx bx-check'></i> App Dynamics</span></li>
                        <li><span><i class='bx bx-check'></i> Pager Duty</span></li>
                        <li><span><i class='bx bx-check'></i> Splunk, New Relic, Data Dog</span></li>
                        <li><span><i class='bx bx-check'></i> Cloud Watch</span></li>
                    </ul>
                </div>
            </div>

            <div class="overview-content" style="padding-top:60px;">
                <div class="content right-content"> 
                    <h2>Container Solutions </h2>
                    <ul class="features-list">
                        <li><span><i class='bx bx-check'></i> Docker</span></li>
                        <li><span><i class='bx bx-check'></i> Kubernetes                        </span></li>
                       
                    </ul>
                </div>
            </div>

        </div>
    </div>
</section>
