import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { AuthService } from 'src/app/services/auth.service';
import * as firebase from 'firebase/app';

@Component({
  selector: 'app-login-page',
  templateUrl: './login-page.component.html',
  styleUrls: ['./login-page.component.scss']
})
export class LoginPageComponent implements OnInit {
  email = '';
  password = '';
  networkError = false;
  loginError = false;
  tooManyRequestsError = false;
  currentUser = null;

  constructor(private router: Router, private authService: AuthService) { }

  ngOnInit(): void {
    if (this.authService.userEmail) {
      // User is signed in.
      this.router.navigateByUrl('/blog');
    }
  }

  submitLogin(loginForm) {
    this.networkError = false;
    this.loginError = false;
    this.tooManyRequestsError = false;

    if (loginForm.valid) {
      this.authService.login(this.email, this.password)
        .catch((error) => {
          console.log(error);
          if (error.code === 'auth/network-request-failed') {
            this.networkError = true;
          }
          else if (error.code === 'auth/user-not-found' || error.code === 'auth/wrong-password') {
            this.loginError = true;
          }
          else if (error.code === 'auth/too-many-requests') {
            this.tooManyRequestsError = true;
          }
        });
    }
  }

}
